import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

const carClarityCalculator = () => {
  let baseUrl = `${process.env.GATSBY_CAR_LOAN_URL}&partner_id=`,
    partnerId = "ab44b478909f4b2aaf2b92ed51379694",
    partnerName = "Oxygen",
    partnerLogoUrl = "https://i.ibb.co/Ns1dKKt/oxygen-logo.png",
    partnerRedirectUrl = "https://oxygen.com.au/",
    environment = "production",
    // default to production...
    // not show the widget if environment is not vaild
    userMobile = "0412345678",
    primaryColourHexValue = "00B3E6",
    secondaryColourHexValue = "29344A",
    FRAME_URL =
      baseUrl +
      partnerId +
      "&partnerName=" +
      partnerName +
      "&partnerLogoUrl=" +
      partnerLogoUrl +
      "&partnerRedirectUrl=" +
      partnerRedirectUrl +
      "&environment=" +
      environment +
      "&userMobile=" +
      userMobile +
      "&primaryColourHexValue=" +
      primaryColourHexValue +
      "&secondaryColourHexValue=" +
      secondaryColourHexValue +
      "&widgetType=white-label-full&utm_medium=affiliate&"

  return (
    <Layout>
      <SEO
        title="Car Loans Comparison | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      {/* <Navbar /> */}
      <div className="container calc-iframe pb-5">
        <div className="loader"></div>
        <iframe
          id={partnerId}
          src={FRAME_URL}
          frameborder="0"
          scrolling="no"
          title="calc-iframe"
        ></iframe>
      </div>
      {/* <CTABanner /> */}
      {/* <Footer /> */}
    </Layout>
  )
}

export default carClarityCalculator
